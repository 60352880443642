import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Styles } from "./styles/styles";
import Router from "./router";

const App = () => (
  
  <BrowserRouter>
      <Styles/> 
      <Router />
  </BrowserRouter>
);

ReactDOM.render(
    <App />, 
document.getElementById("root"));
