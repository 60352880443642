import React from 'react';
import { Link } from 'react-router-dom'
import "./Servicio.css";


function Servicio (props) {
    return(
        
            <button className='Contenedor-Servicio'>
                <Link to = {`/servicios/${props.enlace}`}>
                    <img src={props.icono} alt = {props.alt} style={{borderRadius:'50%', marginBottom: 15}}/>
                    <p className='Contenedor__Titulo'>
                        {props.titulo}
                    </p>
                    
                    <p className='Contenedor__Mensaje'>
                        {props.mensaje}
                    </p>
                </Link>
            </button>
        
    )
}

export default Servicio