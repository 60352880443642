import React, {useEffect} from 'react'
import './Servicios.css'
import BotonColapsable from '../../../components/BotonColapsable/BotonColapsable'
import Galeria from '../../../components/Galeria/Galeria'
import { motion } from 'framer-motion'

function Desarrollos() {

    useEffect(() => {    
        window.scrollTo(0, 0);
    });

    return (

        <motion.div className="container"
            exit={{ translateX: '65vw', opacity: 0, transition: { duration: 0.8 } }}
        >

            <div className='flex-container-sse' >
                <div className="flex-item izq">
                    <img className='Servicio__imagen' src='https://www.caracteristicas.co/wp-content/uploads/2018/08/informatica-e1575679570321.jpg' alt='' />
                </div>


                <div className="flex-item der Servicio__Bloque">

                    <div>
                        <span style={{color: '#a30000'}}>Servicios</span>
                        <h1 style={{ fontSize: '40px', color: '#a30000' }}>Desarrollos</h1>
                        <p>Si desea un producto/servicio que no ofrecemos, puede enviarnos su propuesta y nos pondremos en contacto con usted.</p>
                    </div>


                    <div className="preguntas">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preguntas Frecuentes: </span>

                        <BotonColapsable label='Pregunta 1: " ¿Qué significa este servicio? "'>
                            <div className='mensaje'> Nuestros desarrolladores pueden encargarse de soluciones personalizadas, un ejemplo es el proyecto Super SOS que resultó de una colaboración con la Municipalidad de Coronel. </div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 2: " ¿Dónde puedo enviar mi propuesta? "'>
                            <div className='mensaje'> Envíe su propuesta de desarrollo a info@supervigilancia.cl y nos pondremos en contacto con uds. </div>
                        </BotonColapsable>

                    </div>
                </div>
            </div>
            <motion.div style={{ marginBottom: '30px' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 2 } }}
            >
                <Galeria />
            </motion.div>

        </motion.div>

    )
}

export default Desarrollos