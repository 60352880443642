import React from 'react'
import './Whatsapp.css'

function WhatsApp(){
  return (
        <>
        <a href="https://wa.me/56964910216" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
        </>
  )
}
export default WhatsApp
