import React from 'react';
import "./OtrosServicios.css";
import Servicio from '../Servicio/Servicio';
import { Reveal } from "react-awesome-reveal";
import S_PorteriaRemota from "../../content/Servicios/S_PorteriaRemota.json";
import S_SeguridadElectronica from "../../content/Servicios/S_SeguridadElectronica.json"
import S_Desarrollo from "../../content/Servicios/S_Desarrollo.json"
import S_Monitoreo from "../../content/Servicios/S_Monitoreo.json"
import { Container } from '../../common/Input/styles';

const OtrosServicios = () => (
    <Container>
    <div className='OtrosContainer'>
        
        <div className="OtrosTituloContenedor">
            OTROS SERVICIOS
        </div>
        <br />
        <Reveal duration={900}>
            <div className='Otros-flex-container'>

                <Servicio
                    className='flex-item'
                    titulo={S_SeguridadElectronica.titulo}
                    mensaje={S_SeguridadElectronica.mensaje}
                    icono={S_SeguridadElectronica.icono}
                    alt={S_SeguridadElectronica.alt}
                    enlace={S_SeguridadElectronica.enlace}
                />

                <Servicio
                    className='flex-item'
                    titulo={S_Monitoreo.titulo}
                    mensaje={S_Monitoreo.mensaje}
                    icono={S_Monitoreo.icono}
                    alt={S_Monitoreo.alt}
                    enlace={S_Monitoreo.enlace}
                />

                <Servicio
                    className='flex-item'
                    titulo={S_PorteriaRemota.titulo}
                    mensaje={S_PorteriaRemota.mensaje}
                    icono={S_PorteriaRemota.icono}
                    alt={S_PorteriaRemota.alt}
                    enlace={S_PorteriaRemota.enlace}
                />

                <Servicio
                    className='flex-item'
                    titulo={S_Desarrollo.titulo}
                    mensaje={S_Desarrollo.mensaje}
                    icono={S_Desarrollo.icono}
                    alt={S_Desarrollo.alt}
                    enlace={S_Desarrollo.enlace}
                />

            </div>
        </Reveal>
    </div>
    </Container>
)

export default OtrosServicios