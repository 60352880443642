import React from 'react'
import './PagServ.css'
import { Link } from 'react-router-dom'
import { Slide, Fade } from "react-awesome-reveal";
import superporteria from './superporteria2.jpg'
import supersos from './supersos.jpg'
import {motion} from 'framer-motion'


function PagServ () {
    
    return (
        <>
        <motion.div className="container"
            exit={{translateX: '-65vw', opacity: 0, transition: {duration: 0.8}}}
            >
            <div className='flex-container container-servicios'>
                <Slide direction='right' cascade='true' duration={500} damping={0.1} triggerOnce>
                
                <Fade duration={2500} triggerOnce>
                <Link to ="/servicios/superporteria">
                <div className='Servicios flex-item' style={{backgroundImage: `url(${superporteria})`, backgroundSize:'cover'}}>
                    
                    <div className='ServicioContenido'>
                        <h2 className='ServicioTitulo' >Súper Portería Remota</h2>
                        <p className='ServicioTexto'>
                            "La nueva generación en seguridad para condominios"
                        </p>  
                    </div>
                </div>
                </Link>
                </Fade>

                <Fade duration={2500} triggerOnce>
                <Link to ="/servicios/supersos" style={{color: '#a30000'}}>
                <div className='Servicios flex-item' style={{backgroundImage: `url(${supersos})`, backgroundSize:'cover'}}>
                    <div className='ServicioContenido'>
                        <h2 className='ServicioTitulo'>Súper SOS</h2>
                        <p className='ServicioTexto'>
                            "Emergencias al instante con su grupo de vecinos"
                        </p>
                            
                            
                    </div>
                </div>
                </Link>
                </Fade>
                
                
                <Fade duration={2500} triggerOnce>
                <Link to ="/servicios/seguridadelectronica">
                <div className='Servicios flex-item' style={{backgroundImage: 'url(https://www.linkzartec.com/wp-content/uploads/2020/04/seguridad-electronica-04.jpg)', backgroundSize:'cover'}}>
                    <div className='ServicioContenido'>
                        <h2 className='ServicioTitulo'>Servicio de seguridad electrónica</h2>
                        <p className='ServicioTexto'>
                            "Controlamos los dispositivos eléctricos de su condominio o empresa"
                        </p>
                    </div>
                </div>
                </Link>
                </Fade>

                <Fade duration={2500} triggerOnce>
                <Link to ="/servicios/monitoreo">
                <div className='Servicios flex-item' style={{backgroundImage: 'url(https://www.tecnoseguro.com/media/k2/items/cache/706d39ca08c79601e8763bcd4b9bc178_XL.jpg)', backgroundSize:'cover', backgroundPositionX:'-100px'}}>
                    <div className='ServicioContenido'>
                        <h2 className='ServicioTitulo'>Monitoreo 24/7</h2>
                        <p className='ServicioTexto'>
                            "Personal capacitado para monitoreo 24/7 desde nuestra central"
                        </p>
                    </div>
                </div>
                </Link>
                </Fade>

                <Fade duration={2500} triggerOnce>
                <Link to ="/servicios/porteriaindustrial">
                <div className='Servicios flex-item' style={{backgroundImage: 'url(http://www.arquea.co/wp-content/uploads/2017/11/02-17.jpg)', backgroundSize:'cover'}}>
                    <div className='ServicioContenido'>
                        <h2 className='ServicioTitulo'>Súper Portería Remota Industrial</h2>
                        <p className='ServicioTexto'>
                            "Solución inteligente para la seguridad de su empresa"
                        </p>
                    </div>
                </div>
                </Link>
                </Fade>

                <Fade duration={2500} triggerOnce>
                <Link to ="/servicios/desarrollos" style={{color: '#a30000'}}>
                <div className='Servicios flex-item'style={{backgroundImage: 'url(https://www.caracteristicas.co/wp-content/uploads/2018/08/informatica-e1575679570321.jpg)', backgroundSize:'cover'}}>
                    <div className='ServicioContenido'>
                        <h2 className='ServicioTitulo'>Desarrollos</h2>
                        <p className='ServicioTexto'>
                            "Desea un servicio/producto que no ofrecemos? Envíenos su propuesta"
                        </p>
                    </div>
                </div>
                </Link>
                </Fade>

                </Slide>
            </div>
        </motion.div>
        </>
    )
}

export default PagServ