import "./ServicioPrincipal.css"
import { Link } from 'react-router-dom'

function ServicioPrincipal(props) {

    return (
        <div className='activador' style={{
            backgroundImage: `url(${props.fondo})`,
            backgroundSize: 'cover',
        }}>
            <div className='contenedor'>
                <br />
                <p className='titulo'>
                    {props.titulo}
                </p>
                <br></br>
                <p className='informacion'>
                    {props.texto}
                </p>
                <p className='informacion2'>
                    {props.textoDetalles}
                </p>
                <br/>
                <Link to={`/servicios/${props.boton}`} style={{
                    color: 'white'
                }}>
                    <button className='boton'>
                            Ver Más
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default ServicioPrincipal