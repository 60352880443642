import React from 'react';
import { Slide, Fade } from "react-awesome-reveal";
import "./BloqueServicio.css";
import ServicioPrincipal from '../ServicioPrincipal/ServicioPrincipal';
import SP_SuperPorteria from '../../content/ServicioPrincipal/SP_SuperPorteria.json'
import SP_SuperSOS from "../../content/ServicioPrincipal/SP_SuperSOS.json"
import fondoPorteriaRemota from "./superporteria2.jpg"
import fondoSuperSOS from "./supersos.jpg"

const BloqueServicio = (props) => (

  <>
    <div className='bloque-flex-container'>
      <div className='BS-TituloContenedor'>
        TE PRESENTAMOS
      </div>

      <div className='BS-ServicioPrincipal'>
        <Slide duration={700} direction='left'>
          <div className='flex-item bloque__servicio'>
            <div style={{ margin: '0 2%' }}>
              <ServicioPrincipal
                fondo={fondoPorteriaRemota}
                titulo={SP_SuperPorteria.titulo}
                texto={SP_SuperPorteria.texto}
                textoDetalles={SP_SuperPorteria.textoDetalles}
                boton='superporteria'
              />
            </div>
            <Fade direction="center" duration={2500}>
              <div className='BS-texto'>
                <ul className='flex-item' style={{ fontStyle: 'italic', fontFamily: 'initial' }}>
                  <li className='flex-item' style={{ margin: '50px 0', fontSize: '25px' }}>Automatizamos el condominio y atendemos de forma remota</li>
                  <li className='flex-item' style={{ margin: '50px 0', fontSize: '25px' }}>Utiliza tu smartphone para ingresar a tu condominio, reservar e ingresar a espacios comunes, invitar personas y más</li>
                </ul>
              </div>
            </Fade>
          </div>
        </Slide>


        <Slide duration={700} direction='right'>
          <div className='flex-item bloque__servicio'>
            <Fade direction="center" duration={2500} >
              <div className='BS-texto'>
                <ul className='flex-item' style={{ fontStyle: 'italic', fontFamily: 'initial' }}>
                  <li className='flex-item' style={{ margin: '50px 0', fontSize: '25px' }}>Comunica al instante cualquier problema a tu comunidad</li>
                  <li className='flex-item' style={{ margin: '50px 0', fontSize: '25px' }}>Toda la información sobre quién activo la alarma y dónde, será comunicado en el momento a todos los usuarios</li>
                </ul>
              </div>
            </Fade>
            <div style={{ margin: '0 2%' }}><ServicioPrincipal
              fondo={fondoSuperSOS}
              titulo={SP_SuperSOS.titulo}
              texto={SP_SuperSOS.texto}
              textoDetalles={SP_SuperSOS.textoDetalles}
              boton='supersos'
            />
            </div>
          </div>
        </Slide>
      </div>
    </div>


  </>
)

export default BloqueServicio