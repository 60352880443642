import React, { useState, useRef } from 'react'
import './BotonColapsable.css'

function BotonColapsable(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setActive] = useState(false);
    const parentRef = useRef();

    function abrir() {
        setIsOpen(!isOpen)
        setActive(!isActive)
    }

    return (
        <div className='botonColapsable' style={{ width: `${props.ancho}` }}>

            <button className={isActive ? "toggle activo" : "toggle"} onClick={() => abrir(this)}>
                <svg className="Boton__Icono" width='15' height='10' viewBox='0 0 42 25'>
                    <path d='M3 3L21 21L39 3' stroke='#a30000' strokeWidth='8' fill='none' strokeLinecap='round' />
                </svg>
                <div style={{paddingLeft: '10px'}}> {props.label} </div>
            </button>
            <div
                className='contenidoPadre'
                ref={parentRef}
                style={
                    isOpen
                        ? {
                            height: parentRef.current.scrollHeight + 'px',
                        }
                        : { height: '0px' }
                }>
                <div className='contenido'>
                    {props.children}
                </div>
            </div>

        </div>
    )

}

export default BotonColapsable