import React, { Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import FloatContainer from "../components/FloatContainer/FloatContainer.js";
import { AnimatePresence } from "framer-motion";

const Router = () => {
  const location = useLocation();
  return ( 

<Suspense fallback={null}>
    <Header />
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch location={location} key={location.pathname}>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.componentName}
              path={routeItem.path}
              exact={routeItem.exact}
              component={routeItem.component}
            />
          );
        })}
      </Switch>
      </AnimatePresence>
      <FloatContainer/>
      <Footer />
      </Suspense>
  );
};

export default Router;
