import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  Para,
  Large,
  Chat,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="40px" height="40px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <div style={{margin: '0 auto', paddingRight: 'min(30px, 5vw)', paddingLeft: 'min(30px, 5vw)',maxWidth: '1200px'}}>
          <Row justify="space-between" >
            
            <Col lg={8} md={8} sm={12} xs={24}>
              <Title >Contacto</Title>
              <Para>contacto@supervigilancia.cl</Para>
              <Para>Teléfonos:
                <div className='container'>
                  <li>
                    <i className='fa fa-phone'> +56-9-64910216</i>
                  </li>
                  <li>
                    <i className='fa fa-phone'> +56-9-41878356</i>
                    </li>
                </div>
              </Para>
              <a href="mailto:contacto@supervigilancia.cl">
                <Chat style={{fontSize:'16px'}}>Escríbenos</Chat>
              </a>
              <br/>
            </Col>
            
            <Col lg={8} md={8} sm={12} xs={24}>
              <Title>{("Politicas")}</Title>
              <Large to ="/Terminos_Servicio">Terminos de Servicio</Large>
              <Large to ="/Politica_Privacidad">Politica de Privacidad</Large>
              <br/>
            </Col>

            <Col lg={8} md={8} sm={12} xs={24}>
              <Title style={{textAlign:'center'}}>Ubicación</Title>
              <Para> Condominio Parque Millaray </Para>
              <Para> Pedro Aguirre Cerda 255 </Para>
              <Para> San Pedro de la Paz, Región del Biobio </Para>
              <iframe title = 'Mapa Empresa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.00643627932!2d-73.07866966252834!3d-36.8423219053918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669b606f535f20b%3A0x16d035e751de26d7!2sPedro%20Aguirre%20Cerda%20255%2C%20San%20Pedro%20de%20la%20Paz%2C%20B%C3%ADo%20B%C3%ADo!5e0!3m2!1ses!2scl!4v1631032488044!5m2!1ses!2scl" width="100%" height="auto" allowFullScreen = {true} style={{border:"0"}}  loading="lazy"></iframe>
              <br/>
            </Col>
          </Row>
        </div>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="start"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <SvgIcon src="supervigilancia.png" width="60px" height="auto"/>
            </NavLink>
            <SocialLink
                    href="https://www.facebook.com/Supervigilancia-Ltda-307690409744783"
                    src="facebook.svg"
                  />
                  <SocialLink
                    href="https://www.instagram.com/supervigilancialtda/"
                    src="instagram.svg"
                  />
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
