import React from 'react'
import "./Nosotros.css"
import { motion } from 'framer-motion'

function Nosotros() {
    return (
        <motion.div className='container'
            initial={{ translateX: '100vw' }}
            animate={{ translateX: 0 }}
            transition={{ type: 'spring', bounce: 0.2, duration: 1.2 }}
            exit={{ translateX: '-65vw', opacity: 0, transition: { duration: 0.8 } }}
        >
            <div className='Nosotros__Fondo' id='about'>
                <div className='Nosotros'>
                    <img src='./img/icons/supervigilancia.png' alt='logo' className='logo' />
                    <h1 className='Nosotros__Titulo'>Acerca de SuperVigilancia</h1>
                    <p className='Nosotros__Mensaje'>Bienvenidos a Supervigilancia, empresa dedicada a darte una solución moderna y efectiva para tus problemas de seguridad</p>
                    <div>
                        <img src='/img/imagenes/Chile.png' alt='personas' className='Nosotros_imagen' />
                        <div>
                            <h1 className='Nosotros__Titulo2'>Misión</h1>
                            <p className='Nosotros__Mensaje2'>Mejorar la calidad de la seguridad en las casas, condominios, departamentos y empresas de la Región del Bío-Bío. Lo cual implica automatizar procesos como control de acceso, alerta de activación de alarmas a clientes y autoridades; y desarrollar soluciones personalizadas, para poder instaurarnos como una empresa que ofrece un mejor sentimiento de seguridad y que está a un nivel tecnológico mayor al que las demás empresas del mismo rubro.</p>
                            <h1 className='Nosotros__Titulo2'> Visión</h1>
                            <p className='Nosotros__Mensaje2'>Posicionarnos como una empresa relevante del ámbito de la seguridad en Chile y seguir avanzando tecnológicamente en esta área.</p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Nosotros