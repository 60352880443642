import React from 'react'
import './Clientes.css'
import oxy from './oxy-logo.png'
import tecnogalpones from './tecno-galpones-logo.png'
import coronel from './coronel-logo.png'
import curanilahue from './curanilahue-logo.png'


function Clientes() {
    return (
        <div className='Clientes__Container'>

            <div className='Clientes__Titulo'>
                Nuestros Clientes
                <div className='Clientes__Logo__Container'>

                    <a className='Clientes__Logo' href='https://www.oxychile.cl' target='__blank'>
                        <img className='Clientes__Logo__Imagen' src={oxy} alt='oxy'></img>
                    </a>

                    <a className='Clientes__Logo' href='https://tecno-galpones.cl/' target='__blank'>
                        <img className='Clientes__Logo__Imagen' src={tecnogalpones} alt='tecnogalpones'></img>
                    </a>

                    <a className='Clientes__Logo' href='http://www.coronel.cl/' target='__blank'>
                        <img className='Clientes__Logo__Imagen' src={coronel} alt='coronel'></img>
                    </a>

                    <a className='Clientes__Logo' href='http://www.munichue.cl/' target='__blank'>
                        <img className='Clientes__Logo__Imagen' src={curanilahue} alt='curanilahue'></img>
                    </a>

                </div>
            </div>
        </div>
    )

}
export default Clientes