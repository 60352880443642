import { useState} from "react";
import { notification } from "antd";
import { send} from 'emailjs-com';


export const useForm = (validate: any) => {
  const [values, setValues] = useState({name: "", message: "", email: ""});
  const [errors, setErrors] = useState({name: "", message: "", email: ""});
  const [loading, setLoading] = useState(false);
  
  //const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Enviado",
      description: "Su mensaje fue enviado!",
    });
  };

  const openNotificationWithIconError = () => {
    notification["error"]({
      message: "Error",
      description: "No ha sido enviado.",
    });
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(validate(values))
    
    if (values.name.length !== 0 && values.email.length !== 0 && values.message.length !== 0) {
      if (validate(values).name.length === 0 && validate(values).email.length === 0 && validate(values).message.length === 0) {
        //loading
        setLoading(true);
        send(
          'service_g166enh',
          'template_f3lrxta',
          values,
          'user_KEPXje2W5pN1xiDJVFNSr'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            openNotificationWithIcon()
            //quitar loading
            setLoading(false)
          })
          .catch((err) => {
            console.log('FAILED...', err);
            openNotificationWithIconError()
            //quitar loading
             setLoading(false)
          });
      }
      
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
  };

  return {
    loading,
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};
