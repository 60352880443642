import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { Link } from 'react-router-dom'
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const cerrarMenu = () => {
    if (visible === true) {
      setVisibility(!visible)
    }
  }

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall>
          <Link to="/home" onClick={cerrarMenu} style={{
            color: 'white'
          }}>
            <Span>Inicio</Span>
          </Link>
        </CustomNavLinkSmall>


        <CustomNavLinkSmall>
          <Link to="/servicios" style={{
            color: 'white'
          }}><Span>Servicios</Span>
          </Link>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall>
          <Link to="/nosotros" style={{
            color: 'white'
          }}>
            <Span>Nosotros</Span>
          </Link>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall
          style={{ width: "180px" }}
        >

          <Link to="/contacto" style={{
            color: 'white'
          }}><Button color="white" colorText="black">Contacto</Button>
          </Link>

        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container >
        <Row justify="space-between" id='Navbar'>
          <LogoContainer to="/" aria-label="homepage" style={{backgroundColor: '#eeeeee', padding: '7px 10px', borderRadius: '50%'}}>
            <SvgIcon src="supervigilancia.png" width="auto" height="50em" />
          </LogoContainer>
          <div style={{}}>
            <p style={{
              fontSize: '30px',
              color: 'white',
              margin: '0'
            }}>SuperVigilancia</p>
            <p style={{
              fontSize: '15px',
              color: 'white',
              margin: '0'
            }}>Mayor Seguridad, Menor Costo</p>
          </div>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer maskStyle={{ background: 'rgba(0,0,0,0.6)' }} closable={false} visible={visible} onClose={onClose} drawerStyle={{ background: '#a30000', alignItems: 'center' }}>
          <Col style={{ marginBottom: "2.5rem"}}>
            <Label onClick={onClose} style={{ color: 'white'}}>
              <Col span={12} >
                <Menu style={{ color: 'white' }}>Menu</Menu>
              </Col>
              <Col span={12} style={{ textAlign: 'end'}}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
