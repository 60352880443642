import React, {useEffect} from 'react'
import './Servicios.css'
import BotonColapsable from '../../../components/BotonColapsable/BotonColapsable'
import Galeria from '../../../components/Galeria/Galeria'
import { motion } from 'framer-motion'

function PorteriaIndustrial() {

    useEffect(() => {    
        window.scrollTo(0, 0);
    });

    return (
        <motion.div className="container"
            exit={{ translateX: '65vw', opacity: 0, transition: { duration: 0.8 } }}
        >

            <div className='flex-container-sse' >
                <div className="flex-item izq">
                    <img className='Servicio__imagen' src='http://www.arquea.co/wp-content/uploads/2017/11/02-17.jpg' alt='' />
                </div>


                <div className="flex-item der Servicio__Bloque">

                    <div>
                        <span style={{color: '#a30000'}}>Servicios</span>
                        <h1 style={{ fontSize: '40px', color: '#a30000' }}>Súper Portería Remota Industrial</h1>
                        <p>Similar a la súper portería remota pero orientada a la industria, en donde se automatiza el control de acceso para los trabajadores y a diferencia de la super portería remota para condominios, controlamos más accesos con distintas reglas y distintos niveles de usuario y de acceso. Además facilitamos la información a la administración para que realicen análisis sobre los datos para ayudar a optimizar sus funcionamientos, los cuales pueden ser vistos a través de nuestro dashboard. Integramos además sensores con reglas y alertas visuales o por sonido de emergencias al personal que trabaja en la planta.</p>
                    </div>


                    <div className="preguntas">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preguntas Frecuentes: </span>

                        <BotonColapsable label='Pregunta 1: " ¿ Quién es el encargado de supervisar el sistema ? "'>
                            <div className='mensaje'> El sistema es monitoreado desde nuestra central, y cualquier activación de emergencia, ya sea emergencias como activación de cerco eléctrico, movimientos detectados por la cámara o incluso niveles de gas o temperatura altos, es atendida de inmediato siguiendo los protocolos para atender la emergencia, llamando a bomberos, o carabineros si lo requiere el protocolo. </div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 2: " ¿Que pasa si se corta el internet? "'>
                                <div className='mensaje'> Nuestros dispositivos locales se actualizan en tiempo real, en caso de corte de internet, pasaría a levartar la conexión de respaldo y en caso de fallar también, seguirían funcionando con los últimos datos actualizados. </div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 3: " ¿Qué pasa si se corta la luz? "'>
                                <div className='mensaje'> En caso de corte de luz, el grupo electrógeno entra en acción, además nuestros dispositivos principales se encuentran respaldados por ups. </div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 4: " ¿Y qué pasa si falla algo? "' >
                                <div className='mensaje'> Si algo falla, nuestros sistemas inteligentes detectarán el mal-funcionamiento de algún dispositivo y el técnico acudirá al lugar en el móvil de reacción a resolver el problema. </div>
                            </BotonColapsable>


                    </div>
                </div>
            </div>
            <motion.div style={{ marginBottom: '30px' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 2 } }}
            >
                <Galeria />
            </motion.div>

        </motion.div>
    )
}

export default PorteriaIndustrial

