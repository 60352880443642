import { validateProps } from "../../common/types";

export default function validate(values: validateProps) {
  let errors = {name: "", email: "", message:""} as validateProps;

  if (!values.name) {
    errors.name = "¡Requiere un nombre!";
  }
  if (!values.email) {
    errors.email = "¡Requiere un email!";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "¡Su email es inválido!";
  }
  if (!values.message) {
    errors.message = "¡Requiere un mensaje!";
  }

  return errors;
}
