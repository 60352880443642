import React from "react";
import "./ButtonLoader.css"
import { Button } from '../Button/index';

export default function ButtonLoader (props) {
  return(
    <Button color='#a30000' className="button" disabled={props.isLoading}>
          {props.isLoading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {props.isLoading && <span>Enviando...</span>}
          {!props.isLoading && <span>Enviar</span>}
        </Button>
  );
} 