import styled from "styled-components";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css";
import { Carousel } from "react-responsive-carousel";
import { Slide, Fade } from "react-awesome-reveal";

const contenedorMensaje = {
  position: 'absolute',
  height: '576px',
  width: '100%',
  maxWidth: '1280px',
  left: '0',
  right: '0',
  marginRight: 'auto',
  marginLeft: 'auto',
  textAlign: 'center',
}

const Imagen1TextoSuperior = styled.div`
  position: absolute;
  right: 0;
  top: 200px;
  
  @media (max-width: 768px){
    width: 80%;
    right: 0;
    left: 10%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Imagen1Mensaje1 = styled.p`
  background: #a30000;
  color: #ffffff;
  font-size: 45px;
  padding: 10px;
  margin: 0px;
  display: inline-block;
  font-family: 'Motiva Sans medium',sans-serif;
  

  @media (max-width: 768px){
    font-size: 30px;
    right: 0;
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }

  >b{
    font-family: 'Motiva Sans bold',sans-serif;
  }
`;

const Imagen1Mensaje2 = styled.p`
  background: rgba(0, 0, 0, 0.6); 
  color: #ffffff;
  font-size: 16px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  display: inline-block;

  @media (max-width: 768px){
    font-size: 15px;
    max-width: 85%;
    padding: 0 5px;
  }
`;

const Imagen1TextoInferior = styled.div`
  position: absolute;
  left: 150px;
  bottom: 75px;

  
  @media (max-width: 768px){
    right: 0;
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Imagen1Boton = styled.button`
  background: #a30000;
  color: #ffffff;
  font-size: 20px;
  padding: 15px 30px;
  display: inline;
  cursor: pointer;
  border-radius: 15px;
  transition: transform 0.4s;

  @media (max-width: 768px){
    transform: scale(0.75);
  }

  :hover{
    transform: scale(1.1);
  }
`;

const Imagen2TextoSuperior = styled.div`
  position: absolute;
  right: 80px;
  top: 170px;
  width: 500px;
  
  @media (max-width: 768px){
    width: 70%;
    top: 200px;
    right: 0;
    left: 15%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Imagen2Mensaje1 = styled.p`
  background: rgba(0, 0, 0, 0.4); 
  color: #ffffff;
  font-size: 25px;
  font-style: italic;
  box-decoration-break: clone;
  padding: 5px 12px;
  display: inline;

  @media (max-width: 768px){
    font-size: 15px;
  }
`;

const Imagen3Texto = styled.div`
  position: absolute;
  left: 80px;
  top: 130px;
  width: 370px;
  
  @media (max-width: 768px){
    top: 200px;
    width: 70%;
    right: 0;
    left: 15%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Imagen3Mensaje1 = styled.p`
  background: rgba(0, 0, 0, 0.4); 
  color: #ffffff;
  font-size: 25px;
  font-style: italic;
  box-decoration-break: clone;
  padding: 2.5px 12px; 
  display: inline;

  @media (max-width: 768px){
    font-size: 15px;
    width: 70%;
  }
`;

const scrollTo = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView({
    behavior: "smooth",
  });
};


export default () => (


  <Carousel showStatus={false} showThumbs={false} infiniteLoop autoPlay interval={10000}>
    <div className="Carousel__Container">
      <div style={contenedorMensaje}>
        <Imagen1TextoSuperior>
          <Slide direction="down" duration={1200} >
            <Fade duration={2000}>
              <Imagen1Mensaje1>Bienvenido a <b>SuperVigilancia</b> </Imagen1Mensaje1>
            </Fade>
          </Slide>

          <Slide direction="right" duration={2000}>
            <Fade duration={2000}>
              <Imagen1Mensaje2>Servicios innovadores de seguridad electrónica</Imagen1Mensaje2>
            </Fade>
          </Slide>
        </Imagen1TextoSuperior>

        <Imagen1TextoInferior>
          <Slide direction="up" duration={2000} delay={1000}>
            <Fade duration={2000} delay={1000}>
              <Imagen1Boton onClick={() => scrollTo("intro")}>Conoce Más</Imagen1Boton>
            </Fade>
          </Slide>
        </Imagen1TextoInferior>
      </div>
      <img alt="fondo1" src="./img/fondos/fondo1.jpg" style={{
        objectFit: "cover", zIndex: '-1', position: 'relative'
      }} />
    </div>

    {/*IMAGEN 2*/}
    <div className="Carousel__Container">
      <div style={contenedorMensaje}>
        <Imagen2TextoSuperior>
          <Slide direction="left" duration={2000}>
            <Fade duration={2000}>
              <Imagen2Mensaje1> "Ofrecemos servicios de seguridad modernos y soluciones innovadoras a problemas de seguridad en condominios y empresas."</Imagen2Mensaje1>
            </Fade>
          </Slide>
        </Imagen2TextoSuperior>
      </div>
      <img alt="fondo2" src="./img/fondos/fondo2.jpg" style={{
        objectFit: "cover", zIndex: '-1', position: 'relative'
      }} />
    </div>

    {/*IMAGEN 3*/}
    <div className="Carousel__Container">
      <div style={contenedorMensaje}>
        <Imagen3Texto>
          <Slide direction="down" duration={2000}>
            <Fade duration={2000}>
              <Imagen3Mensaje1> "Sabemos en tiempo real lo que pasa en su condominio, desde el control de acceso, hasta del estado de bombas de agua, fugas de gas, incendios, inundaciones, ruidos molestos, etc."</Imagen3Mensaje1>
            </Fade>
          </Slide>
        </Imagen3Texto>
      </div>
      <img alt="fondo3" src="./img/fondos/fondo3.jpg" style={{
        objectFit: "cover", zIndex: '-1', position: 'relative'
      }} />
    </div>
  </Carousel>
);
