import React, {useEffect} from 'react'
import './Servicios.css'
import BotonColapsable from '../../../components/BotonColapsable/BotonColapsable'
import Galeria from '../../../components/Galeria/Galeria'
import { motion } from 'framer-motion'

function ServicioSeguridadElectronica() {

    useEffect(() => {    
        window.scrollTo(0, 0);
    });

    return (
        <motion.div className="container"
            exit={{ translateX: '65vw', opacity: 0, transition: { duration: 0.8 } }}>
            <div className='flex-container-sse' >
                <div className="flex-item izq">
                    <img className='Servicio__imagen' src='https://www.linkzartec.com/wp-content/uploads/2020/04/seguridad-electronica-04.jpg' alt='' />
                </div>


                <div className="flex-item der Servicio__Bloque">

                    <div>
                        <span style={{ color: '#a30000' }}>Servicio</span>
                        <h1 style={{ fontSize: '40px', color: '#a30000'}}>Seguridad Electronica</h1>
                        <p>Nosotros monitoreamos en tiempo real el estado de alarmas, cámaras, control de acceso, sensores perimetrales, sensores de gas, humedad, ruido, de forma de poder tener una imágen clara de lo que ocurre en cada lugar y solucionar problemas de seguridad para la comunidad como puede ser un robo, un problema en una bomba de agua, un incendio, una fuga de gas, y mucho más.</p>
                    </div>


                    <div className="preguntas">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preguntas Frecuentes: </span>

                        <BotonColapsable label='Pregunta 1: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 2: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 3: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 4: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 5: " ¿Lorem ipsum dolor sit amet consectetur? "' >
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                    </div>
                </div>
            </div>
            <motion.div style={{ marginBottom: '30px' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 2 } }}
            >
                <Galeria />
            </motion.div>

        </motion.div>

    )
}

export default ServicioSeguridadElectronica