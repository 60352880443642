import React from "react";
import ContactContent from "../../content/ContactContent.json";
import Carousel from "../../common/Carousel/Carousel";
import OtrosServicios from "../../components/OtrosServicios/OtrosServicios";
import BloqueServicio from "../../components/BloqueServicio/BloqueServicio";
import Clientes from "../../components/Clientes/Clientes";
import './Home.css'
import { motion } from "framer-motion";
import Container from "../../common/Container"
import Contact from "../../components/ContactForm/index"


const Home = () => {
  return (
    <>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
    >
      <Carousel />
      <div id="intro" className='MensajeHome '>
        Una nueva generación en seguridad llega a Chile
      </div>
      <BloqueServicio />
      <div className='MensajeHome'>
        Y para mantener tu protección al máximo tenemos más opciones para ti
      </div>
      <OtrosServicios />
      <Clientes />
      <Container>
        <Contact
          title={ContactContent.title}
          content={ContactContent.text}
          id="contact"
        />
      </Container>
    </motion.div>
    </>
  );
};

export default Home;
