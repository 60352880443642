import React from 'react'
import ContactContent from "../../content/ContactContent.json";
import { motion } from 'framer-motion';
import ContactForm from '../../components/ContactForm';

function Contacto(){
    return (
        <motion.div className='container'
         exit={{translateY: '50vh', opacity: 0, transition: {duration: 0.8}}}>
            <ContactForm
            title={ContactContent.title}
            content={ContactContent.text}
            className='flex-item'
            />
        </motion.div>
    )
} export default Contacto
