import React from 'react'
import WhatsApp from '../Whatsapp/Whatsapp'
import './FloatContainer.css'
import ScrollToTop from '../../common/ScrollToTop'

function FloatContainer(){
    return(
        <div className='FContainer'>
            <WhatsApp/>
            <ScrollToTop/>
        </div>
    )
}

export default FloatContainer