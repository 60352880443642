import Home from "../pages/Home";
import Nosotros from '../pages/Nosotros/Nosotros';
import PagServ from "../pages/Servicios/PagServ";
import Contacto from "../pages/Contacto/Contacto";
import ServicioSeguridadElectronica from "../pages/Servicios/PaginasServicios/ServicioSeguridadElectronica";
import Desarrollos from "../pages/Servicios/PaginasServicios/Desarrollos";
import SuperPorteria from "../pages/Servicios/PaginasServicios/SuperPorteria";
import SuperSOS from "../pages/Servicios/PaginasServicios/SuperSOS";
import Monitoreo from "../pages/Servicios/PaginasServicios/Monitoreo";
import PorteriaIndustrial from "../pages/Servicios/PaginasServicios/PorteriaIndustrial";
import TerminosServicio from "../pages/TerminosServicio/TerminosServicio";
import Politicas from "../pages/Politicas/Politicas";


const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    componentName: "Home",
    component: Home
  },
  {
    path: ["/", "/nosotros"],
    exact: true,
    componentName: "Nosotros/Nosotros.js",
    component: Nosotros
  },

  {
    path: ["/", "/servicios"],
    exact: true,
    componentName: "Servicios/PagServ.js",
    component: PagServ
  },

  
  {
    path: ["/", "/servicios/seguridadelectronica"],
    exact: true,
    componentName: "Servicios/PaginasServicios/ServicioSeguridadElectronica.js",
    component: ServicioSeguridadElectronica
  },

  {
    path: ["/", "/servicios/monitoreo"],
    exact: true,
    componentName: "Servicios/PaginasServicios/Monitoreo.js",
    component: Monitoreo
  },

  {
    path: ["/", "/servicios/porteriaindustrial"],
    exact: true,
    componentName: "Servicios/PaginasServicios/PorteriaIndustrial.js",
    component: PorteriaIndustrial
  },

  {
    path: ["/", "/servicios/desarrollos"],
    exact: true,
    componentName: "Servicios/PaginasServicios/Desarrollos.js",
    component: Desarrollos
  },
  
  {
    path: ["/", "/servicios/superporteria"],
    exact: true,
    componentName: "Servicios/PaginasServicios/SuperPorteria.js",
    component: SuperPorteria
  },

  {
    path: ["/", "/servicios/supersos"],
    exact: true,
    componentName: "Servicios/PaginasServicios/SuperSOS.js",
    component: SuperSOS
  },

  {
    path: ["/", "/Terminos_Servicio"],
    exact: true,
    componentName: "TerminosServicio/TerminosServicio.js",
    component: TerminosServicio
  },

  {
    path: ["/", "/Politica_Privacidad"],
    exact: true,
    componentName: "Politicas/Politicas.js",
    component: Politicas
  },

  {
    path: ["/", "/Contacto"],
    exact: true,
    componentName: "Contacto/Contacto.js",
    component: Contacto
  },

];

export default routes;
