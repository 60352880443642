import React, { useEffect } from 'react'
import './Servicios.css'
import superporteria from '../superporteria2.jpg'
import Galeria from '../../../components/Galeria/Galeria'
import BotonColapsable from '../../../components/BotonColapsable/BotonColapsable'
import { motion } from 'framer-motion'

function SuperPorteria() {
    
    useEffect(() => {    
            window.scrollTo(0, 0);
        });
    
    return (
        <motion.div className="container"
            exit={{ translateX: '65vw', opacity: 0, transition: { duration: 0.8 } }}
        >

            <div className='flex-container-sse' >
                <div className="flex-item izq">
                    <img className='Servicio__imagen' src={superporteria} alt='' />
                </div>


                <div className="flex-item der Servicio__Bloque">

                    <div>
                        <span style={{ color: '#a30000' }}>Servicios</span>
                        <h1 style={{ fontSize: '40px', color: '#a30000' }}>Súper Porteria Remota</h1>
                        <p> El sistema de Súper Portería Remota aumenta la seguridad de los condominios al automatizar distintos procesos, pero principalmente el acceso, el cual en la mayoría de los casos depende de la memoria de los conserjes o anotaciones en papel, las cuales no son confiables y no siempre se respetan.</p>
                        <p> Por lo que se propone este sistema el cual remótamente controla y supervisa el ingreso de sólamente personal autorizado al condominio, utilizando dispositivos de lectura de identidad y que se comunican por internet a nuestra central.</p>
                        <p> Este sistema se encuentra interconectado con la nube, la central de operaciones de Supervigilancia, los condominios y las app de los clientes. Maneja las comunicaciones necesarias para que pueda haber una interacción entre los usuarios y los dispositivos instalados en los departamentos. De esta forma desde la central y desde la app pueden ejecutar acciones que repercuten en los lugares físicos, como apertura de portones, puertas, encendido de luces, acceso por credencial, etc. Los eventos son supervisados por el sistema y dependiendo del tipo de evento y la prioridad pueden gatillar diferentes respuestas, como una alerta en la central de fuga de agua, o una alerta en la App del cliente que le ha llegado una correspondencia.</p>
                    </div>
                </div>

                <div className='Servicio__Linea' style={{ borderTop: '2px #a30000 dotted', paddingTop:'30px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <div className="flex-item izq">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/8QW3wtWRdgw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                    <div className="flex-item der Servicio__Bloque">
                        <div className="preguntas">
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preguntas Frecuentes: </span>

                            <BotonColapsable label='Pregunta 1: " ¿Pagaré más de lo que pagamos por el portero/conserje? "'>
                                <div className='mensaje'> No, todo lo contrario, pagará menos ya que nosotros automatizamos los procesos de los condominios, reduciendo la interacción humano-conserje y maximizando la seguridad y el control, lo cual nos permite ofrecer un precio competitivo y menor al que paga actualmente.</div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 2: " ¿Las cámaras se pagan con gastos comúnes? "'>
                                <div className='mensaje'> No, las instalamos nosotros y son parte del servicio completo. </div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 3: " ¿Que pasa si se corta el internet? "'>
                                <div className='mensaje'> Nuestros dispositivos locales se actualizan en tiempo real, en caso de corte de internet, pasaría a levartar la conexión de respaldo y en caso de fallar también, seguirían funcionando con los últimos datos actualizados. </div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 4: " ¿Qué pasa si se corta la luz? "'>
                                <div className='mensaje'> En caso de corte de luz, el grupo electrógeno entra en acción, además nuestros dispositivos principales se encuentran respaldados por ups. </div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 5: " ¿Y qué pasa si falla algo? "' >
                                <div className='mensaje'> Si algo falla, nuestros sistemas inteligentes detectarán el mal-funcionamiento de algún dispositivo y el técnico acudirá al lugar en el móvil de reacción a resolver el problema. </div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 6: " ¿Le sirve a mi condominio? "' >
                                <div className='mensaje'> Para contratar nuestros servicios debe consultar con nosotros en info@supervigilancia.cl </div>
                            </BotonColapsable>

                        </div>
                    </div>
                </div>

            </div>
            <motion.div style={{ marginBottom: '30px' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 2 } }}
            >
                <Galeria />
            </motion.div>
        </motion.div>
    )
}

export default SuperPorteria