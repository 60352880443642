import React, {useEffect} from 'react'
import './Servicios.css'
import BotonColapsable from '../../../components/BotonColapsable/BotonColapsable'
import Galeria from '../../../components/Galeria/Galeria'
import { motion } from 'framer-motion'

function Monitoreo() {

    useEffect(() => {    
        window.scrollTo(0, 0);
    });

    return (

        <motion.div className="container"
            exit={{ translateX: '65vw', opacity: 0, transition: { duration: 0.8 } }}
        >

            <div className='flex-container-sse' >
                <div className="flex-item izq">
                    <img className='Servicio__imagen' src='https://www.tecnoseguro.com/media/k2/items/cache/706d39ca08c79601e8763bcd4b9bc178_XL.jpg' alt='' />
                </div>


                <div className="flex-item der Servicio__Bloque">

                    <div>
                        <span style={{color: '#a30000'}}>Servicios</span>
                        <h1 style={{ fontSize: '40px', color: '#a30000' }}>Monitoreo 24/7</h1>
                        <p>Desde nuestra central de operaciones, disponemos de personal capacitado y especializado en la atención de emergencias y servicio a nuestros clientes, además nuestros servidores registran toda actividad de acceso, videos de las cámaras, etc. (toda la información que registramos es totalmente privada y no se entrega a nadie sin el consentimiento de las personas individuales / comunidad)</p>
                    </div>


                    <div className="preguntas">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preguntas Frecuentes: </span>

                        <BotonColapsable label='Pregunta 1: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 2: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 3: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 4: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                        <BotonColapsable label='Pregunta 5: " ¿Lorem ipsum dolor sit amet consectetur? "' >
                            <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                        </BotonColapsable>

                    </div>
                </div>
            </div>
            <motion.div style={{ marginBottom: '30px' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 2 } }}
            >
                <Galeria />
            </motion.div>

        </motion.div>
    )
}

export default Monitoreo