import React from 'react'
import './Servicios.css'
import supersos from '../supersos.jpg'
import Galeria from '../../../components/Galeria/Galeria'
import BotonColapsable from '../../../components/BotonColapsable/BotonColapsable'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

function SuperSOS() {

    useEffect(() => {    
        window.scrollTo(0, 0);
    });

    return (

        <motion.div className="container"
            exit={{ translateX: '65vw', opacity: 0, transition: { duration: 0.8 } }}
        >

            <div className='flex-container-sse' >
                <div className="flex-item izq">
                    <img className='Servicio__imagen' src={supersos} alt='' />
                </div>


                <div className="flex-item der Servicio__Bloque">

                    <div>
                        <span style={{ color: '#a30000' }}>Servicios</span>
                        <h1 style={{ fontSize: '40px', color: '#a30000' }}>Súper SOS</h1>
                        <p style={{ marginBottom: '10px' }}><b>Problema</b></p>
                        <p>Los botones de pánico y sirenas que se usan en las comunidades para alertar a los vecinos son imprecisas y no dan mucha información </p>
                        <br />

                        <p style={{ marginBottom: '10px' }}><b>Solución</b></p>
                        <p>SuperSOS transmite la emergencia a través de la aplicación, alertando a los vecinos y entrega información del tipo de emergencia y los datos del vecino en peligro, como la ubicación, etc.</p>
                        <br />

                        <p style={{ marginBottom: '10px' }}><b>Central de Monitoreo</b></p>
                        <p>Opcionalmente puede contratar el servicio de monitoreo en la Central de SuperVigilancia para encarganos del despacho de Móviles de Asistencia, Ambulancia y Carabineros.</p>

                    </div>
                </div>

                <div className='Servicio__Linea' style={{ borderTop: '2px #a30000 dotted', paddingTop: '30px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div className="flex-item izq">
                        <iframe className='Servicio__Video' src="https://www.youtube.com/embed/fFmJDjTmAAE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                    </div>

                    <div className="flex-item der Servicio__Bloque">
                        <div className="preguntas">
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preguntas Frecuentes: </span>

                            <BotonColapsable label='Pregunta 1: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                                <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 2: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                                <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 3: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                                <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 4: " ¿Lorem ipsum dolor sit amet consectetur? "'>
                                <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                            </BotonColapsable>

                            <BotonColapsable label='Pregunta 5: " ¿Lorem ipsum dolor sit amet consectetur? "' >
                                <div className='mensaje'> Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia Lorem ipsum dolor sit amet consectetur, adipiscing elit donec cubiliaLorem ipsum dolor sit amet consectetur, adipiscing elit donec cubilia</div>
                            </BotonColapsable>

                        </div>
                    </div>
                </div>

            </div>
            <motion.div style={{ marginBottom: '30px' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 2 } }}
            >
                <Galeria />
            </motion.div>
        </motion.div>
    )
}

export default SuperSOS